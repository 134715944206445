import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TextContainer = styled(motion.div)`
  font-size: 40px;
  line-height: 40px;
  @media only screen and (min-width: 350px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media only screen and (min-width: 450px) {
    font-size: 60px;
    line-height: 60px;
  }
  
  display: flex;
  flex-direction: column;
  font-weight: 700;
  color: #000000;
  transform-origin: left center;
`;

const BlurredText = ({ children }) => (
  <TextContainer
    transition={{
      ease: 'easeOut',
      duration: 1,
      delay: 0.5,
    }}
    initial={{
      opacity: 0,
      filter: 'blur(25px)',
      scale: 2,
    }}
    animate={{
      opacity: 1,
      filter: 'blur(0px)',
      scale: 1,
    }}
  >
    {children}
  </TextContainer>
);

BlurredText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlurredText;
