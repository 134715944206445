import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseText = styled.span`
  font-size: 18pt;
  line-height: 18pt;
  font-weight: 700;
`;

const SolidText = styled(BaseText)`
  color: ${({ theme }) => theme.colors.primary};
  @supports (-webkit-text-stroke: 1px #FFFFFF) {
    -webkit-text-stroke: 1px ${({ theme }) => theme.colors.primary};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.primary};
  }
`;

const OutlinedText = styled(BaseText)`
  color: #FFFFFF;
  @supports (-webkit-text-stroke: 1px #FFFFFF) {
    -webkit-text-stroke: 1px ${({ theme }) => theme.colors.primary};
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = ({ children }) => (
  <Container>
    {[...Array(children.length + 1)].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <TextContainer key={`title-${index}`}>
        {index < children.length ? (
          <SolidText>
            {children.substring(0, children.length - index)}
          </SolidText>
        ) : null}
        {index > 0 ? (
          <OutlinedText>
            {children.substring(children.length - index, children.length)}
          </OutlinedText>
        ) : null}
      </TextContainer>
    ))}
  </Container>
);

Title.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Title;
