import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Layout from '../containers/layout';
import PageTransition from '../components/common/page-transition';
import BlurredText from '../components/home/blurred-text';
import Title from '../components/home/title';
import ClippedLinkText from '../components/home/clipped-link-text';
import IncrementalLinkTransition from '../components/home/incremental-link-transition';

const RootContainer = styled.div`
  z-index: 20;
  top: 4%;
  width: 100%;
  height: 95%;
  display: flex;
  align-items: center;
  position: fixed;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 1000px;
  display: flex;
  align-items: center;
  position: relative;
`;

const PhotoContainer = styled(motion.div)`
  width: 60vw;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Photo = styled.div`
  width: 80%;
  max-width: 500px;
  height: 80%;
  max-height: 700px;
  background-image: url("/assets/home-photo.jpeg");
  background-size: 110% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
`;

const TitleContainer = styled(motion.div)`
  height: 95%;
  @media only screen and (min-height: 750px) {
    height: 80%;
  }

  max-height: 700px;
  margin-left: 20px;
  margin-top: -5px;
`;

const BlurredTextContainer = styled.div`
  margin-bottom: 12vh;
`;

const OverlappedText = styled.div`
  width: auto;
  right: auto;
  left: 16vw;
  @media only screen and (min-width: 650px) {
    width: 56vw;
    right: 0;
    left: auto;
  }
  @media only screen and (min-width: 800px) {
    width: 48vw;
  }
  @media only screen and (min-width: 2000px) {
    width: 45vw;
  }

  top: 25vh;
  @media only screen and (min-height: 750px) {
    top: 28vh;
  }

  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const LinksContainer = styled(motion.div)`
  position: relative;
`;

const Home = () => (
  <Layout>
    <PageTransition>
      <RootContainer>
        <ContentContainer>
          <PhotoContainer
            transition={{
              ease: 'easeOut',
              duration: 2,
            }}
            initial={{
              opacity: 0.4,
              x: -100,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
          >
            <Photo />
          </PhotoContainer>
          <TitleContainer
            transition={{
              ease: 'easeOut',
              duration: 2,
            }}
            initial={{
              opacity: 0.4,
              x: -60,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
          >
            <Title>HOME</Title>
          </TitleContainer>
          <OverlappedText>
            <BlurredTextContainer>
              <BlurredText>
                <span>INDUSTRIAL</span>
                <span>DESIGNER</span>
              </BlurredText>
            </BlurredTextContainer>
            <LinksContainer
              transition={{
                ease: 'easeOut',
                duration: 1.5,
                delay: 1.5,
              }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
            >
              <IncrementalLinkTransition index={0}>
                <ClippedLinkText to="/projects">PROJECTS</ClippedLinkText>
              </IncrementalLinkTransition>
              <IncrementalLinkTransition index={1}>
                <ClippedLinkText to="/resume">RESUME</ClippedLinkText>
              </IncrementalLinkTransition>
              <IncrementalLinkTransition index={2}>
                <ClippedLinkText to="/contact">CONTACT</ClippedLinkText>
              </IncrementalLinkTransition>
            </LinksContainer>
          </OverlappedText>
        </ContentContainer>
      </RootContainer>
    </PageTransition>
  </Layout>
);

export default Home;
