import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TransitioningLink from '../common/transitioning-link';

const StyledLink = styled(TransitioningLink)`
  text-decoration: none;
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;

  &:hover > #inner-text {
    -webkit-text-stroke: 2px #FFFFFF;
    -webkit-text-fill-color: #FFFFFF;
    background-size: 100% 100%;
  }

  &:hover > #outer-text {
    -webkit-text-stroke: 2px #FFFFFF;
    -webkit-text-fill-color: #FFFFFF;
  }
`;

const Text = styled.span`
  font-size: 40px;
  line-height: 40px;
  @media only screen and (min-width: 350px) {
    font-size: 50px;
    line-height: 50px;
  }
  @media only screen and (min-width: 450px) {
    font-size: 60px;
    line-height: 60px;
  }

  font-weight: 700;
`;

const OuterText = styled(Text)`
  position: absolute;
  z-index: 30;

  color: ${({ theme }) => theme.colors.primary};;
  @supports (-webkit-text-stroke: 2px #FFFFFF) {
    -webkit-text-stroke: 2px ${({ theme }) => theme.colors.primary};
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
  }

  clip-path: inset(0 0 0 calc(60vw - 16vw));
  @media only screen and (min-width: 650px) {
    clip-path: inset(0 0 0 16vw);
  }
  @media only screen and (min-width: 800px) {
    clip-path: inset(0 0 0 8vw);
  }
  @media only screen and (min-width: 2000px) {
    clip-path: inset(0 0 0 5vw);
  }
`;

const InnerText = styled(Text)`
  z-index: 20;

  color: #FFFFFF;
  @supports (-webkit-text-stroke: 2px #FFFFFF) {
    -webkit-text-stroke: 2px #FFFFFF;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
  }

  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.primary} 100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 0px 0px;
  transition: background-size 0.25s;
`;

const ClippedLinkText = ({ children, to }) => (
  <StyledLink
    to={to}
    state={{ from: '/' }}
    exit={{
      length: 0.4,
      state: {
        test: 'test',
      },
    }}
    entry={{
      length: 0.4,
      delay: 0.4,
    }}
  >
    <Container>
      <OuterText id="outer-text">
        {children}
      </OuterText>
      <InnerText id="inner-text">
        {children}
      </InnerText>
    </Container>
  </StyledLink>
);

ClippedLinkText.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default ClippedLinkText;
