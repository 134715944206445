import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const LinkContainer = styled(motion.div)`
  position: absolute;
`;

const IncrementalLinkTransition = ({ children, index }) => (
  <LinkContainer
    transition={{
      ease: 'easeOut',
      duration: 1.5,
      delay: 1.25,
    }}
    initial={{
      top: 0,
    }}
    animate={{
      top: `calc(${index} * 7vh)`,
    }}
  >
    {children}
  </LinkContainer>
);

IncrementalLinkTransition.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

export default IncrementalLinkTransition;
